import React from 'react';

import { Loading } from '@alpine-iq/ui-library';
import { cssNum } from '@/helpers';

export type LoaderProps = {
	fullPage?: boolean;
	padding?: string;
	height?: string;
	fullPageMinusNav?: boolean;
	style?: React.CSSProperties;
};

export default function Loader({ height, fullPageMinusNav, fullPage, ...props }: LoaderProps) {
	const heightValue = height ? cssNum(height) : fullPage ? '100vh' : fullPageMinusNav ? 'calc(100vh - var(--app-header-height))' : '100%';

	return (
		<div
			css={`
				position: relative;

				display: flex;
				justify-content: center;
				align-items: center;

				height: ${heightValue};
				width: 100%;

				padding: ${cssNum(props.padding || 'auto')};
			`}
			style={props.style}
		>
			<Loading {...props} />
		</div>
	);
}
